<template>
<div>
    <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia tempore enim quibusdam eligendi nemo reiciendis maxime sit earum deleniti ratione nobis possimus nam perferendis ullam cupiditate explicabo aspernatur ut, autem facilis? Officia reprehenderit quis, harum, reiciendis unde et eos enim similique rem, atque molestiae veritatis porro beatae voluptatum quae consectetur aspernatur sit quo facere dicta id nulla pariatur? Voluptates, nam aliquam, provident incidunt voluptatem alias possimus dicta maxime id animi tempore eius aperiam ipsam quo facere, distinctio commodi molestiae cum.
    </div>
    <div id="printMe">
        <table style="width:100%" cellpadding="5" >
            <tr>
                <td></td>
                <td style="text-align:center; width: 100%; font-size:25px;">"Ели Млели"</td>
                <td></td>
            </tr>
            <tr>
                <td></td>
               <td style="text-align:center; width: 100%; font-size:25px;">пер. Доломановский, 70Д</td>
           <td></td>
            </tr>
            <tr>
                <th>№</th>
                <th>Наименование</th>
                <tth>Кол-во</tth>
            </tr>
             <tr>
                <td>1</td>
                <td>Наименование</td>
                <td>10</td>
            </tr>
               <tr>
                <td>2</td>
                <td>Наименование</td>
                <td>10</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Наименование Наименование</td>
                <td>10</td>
            </tr>
            <tr>
                <td  style="font-size:30px;">Итог:</td>
                <td></td>
                <td  style="font-size:30px;">320.00</td>
            </tr>
            <tr>
                <td colspan="2" width="100%">----------------------------</td>
                <td ></td>
                <td></td>
            </tr>
        </table>
    </div>
    <button @click="print">sfsfsf</button>
</div>
</template>

<script>
export default {
    data() {
        return {
            output: null
        }
    },
    methods: {
        async print() {
            // Pass the element id here
            await this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style lang="scss">
#printMe {
    background: #000;
    width: 302px;
    height: 755px;
    color: azure;
}
</style>
